import { graphql, Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import reactStringReplace from 'react-string-replace';

import "../css/@wordpress/block-library/build-style/style.css";
import "../css/@wordpress/block-library/build-style/theme.css";
import logo from "../images/logo.png";
import * as s from "../styles/blog-post.module.css";

import LastNews from "../components/blog/lastNews/lastNews";
import PDFDownloadBlock from "../components/blog/PDFDownloadBlock/PDFDownloadBlock";
import PDFDownloadBlockMobile from "../components/blog/PDFDownloadBlock/PDFDownloadBlockMobile";
import SubscribeBlock from "../components/blog/subscribeBlock/subscribeBlock";
import ContactForm from "../components/build/contactForm/contactForm";
import LayoutPost from "../components/layout-post";
import AuthorBio from './../components/author/author';
import QrMenuMaker from "./../components/blog/qr-menu-maker/qr-menu-maker";
import Seo from "./../components/seo";


const BlogPostTemplate = ({ data: { previous, next, post, author, site }, location }) => {
  const bp = useBreakpoint();
  const image = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  };

  const [popup, setPopup] = useState(null);

  useEffect(() => {
    if (post?.categories?.nodes?.length > 0) {
      let category = post?.categories?.nodes?.find((c) => !!c?.categoryPopup?.popup?.show)
      if (!!category) {
        setPopup(category);
      }
    }
  }, []);

  // let description = 'description';

  const schema = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": post.title,
    "url": `${site.siteMetadata.siteUrl}${post.uri}`,
    "datePublished": post.published,
    "dateModified": post.modified,
    "publisher": {
      "@type": "Organization",
      "name": "Orders.co",
      "url": `${site.siteMetadata.siteUrl}`,
      "logo": {
        "@type": "ImageObject",
        "url": `${site.siteMetadata.siteUrl}${logo}`,
        "width": 165,
        "height": 40
      }
    },
    "author": {
      "@type": "Person",
      "name": `${author.name}`,
      "url": `${site.siteMetadata.siteUrl}${author.uri}`,
      "sameAs": []
    },
    "image": {
      "@type": "ImageObject",
      "url": `${site.siteMetadata.siteUrl}${image.data?.images?.fallback?.src}`,
      "width": post.featuredImage?.node?.localFile?.childImageSharp?.original?.width,
      "height": post.featuredImage?.node?.localFile?.childImageSharp?.original?.height
    },
    // "keywords": post.seo.metaKeywords,
    "description": post.seo.metaDesc,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${site.siteMetadata.siteUrl}/blog`,
    }
  };

  let contentWithShortcodeBlocks = reactStringReplace(post.content, '<p>[subscribe_form]</p>', (match, i) => (
    <SubscribeBlock />
  ));

  contentWithShortcodeBlocks = reactStringReplace(contentWithShortcodeBlocks, '<p>[qr_menu_banner]</p>', (match, i) => (
    <QrMenuMaker />
  ));

  contentWithShortcodeBlocks = reactStringReplace(contentWithShortcodeBlocks, '<p>[pdf_download_form]</p>', (match, i) => (
    <PDFDownloadBlock block={post.acf_post.pdfDownload} post={post.title} />
  ));

  let relatedPosts = {
    nodes: []
  };

  if (post?.acf_post?.relatedPosts?.length > 0) {
    relatedPosts.nodes = post.acf_post.relatedPosts.map((item) => {
      return item.post;
    })
  }
  console.log(relatedPosts)
  return (
    <LayoutPost>
      <Seo title={post.title} description={post.excerpt} pageSeo={post} schema={schema} />
      <article className="blog-post">
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <section className={s.heading}>
                <div className={s.breadcrumbs}>
                  <Link to="/" className={s.beadcrumbItem}>
                    Home
                  </Link>{" "}
                  /
                  <Link to="/blog/" className={s.beadcrumbItem}>
                    Blog
                  </Link>{" "}
                  /<span className={s.beadcrumbItem}>{parse(post.title)}</span>
                </div>
                <div className={`${s.postDate} d-md-none`}>
                  {post.date}
                </div>
                <h1>{parse(post.title)}</h1>
              </section>
              <AuthorBio author={author} />
            </div>
            <div className="col-xl-8">
              <div className="d-flex align-items-center justify-content-between">
                {post?.categories?.nodes?.length > 0 &&
                  <div className={s.categories}>
                    {post?.categories?.nodes?.map((cat, i) => {
                      return <Link to={cat.uri} className={`${s.category}`} key={`cat-${i}`}>
                        {cat.name}
                      </Link>
                    })}
                  </div>
                }

                <div className={`${s.postDate} d-none d-md-block`}>
                  {post.date}
                </div>
              </div>
              <section className={s.feauturedImage}>
                {!!post?.readingTime?.text && <div className={s.readTime}><svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.99974 0.42627C13.4589 0.42627 17.0737 4.0411 17.0737 8.50023C17.0737 12.9593 13.4589 16.5742 8.99974 16.5742C4.54061 16.5742 0.925781 12.9593 0.925781 8.50023C0.925781 4.0411 4.54061 0.42627 8.99974 0.42627ZM7.53174 3.36225C7.12658 3.36225 6.79775 3.69108 6.79775 4.09625V9.96822C6.79775 10.3734 7.12658 10.7022 7.53174 10.7022H11.9379C12.3431 10.7022 12.6719 10.3734 12.6719 9.96822C12.6719 9.56305 12.3431 9.23422 11.9379 9.23422H8.26574V4.09625C8.26574 3.69108 7.93691 3.36225 7.53174 3.36225Z" fill="#FF8A00" />
                </svg><span>{post?.readingTime?.text}</span></div>}
                {/* if we have a featured image for this post let's display it */}
                {image?.data && (
                  <div>
                    <GatsbyImage
                      placeholder="none"
                      loading="eager"
                      image={image.data}
                      alt={image.alt}
                      style={{ marginBottom: 29 }}
                    />
                  </div>
                )}
              </section>

              {!!post.content && (
                <section className={`${s.postContent}`}>
                  {/* {parse(post.content)} */}
                  {contentWithShortcodeBlocks?.map((part, i) => {
                    return <div key={`content-part-${i}`}>
                      {
                        (typeof part) == 'string'
                          ? parse(part)
                          : part
                      }
                    </div>
                  })}
                  <SubscribeBlock />
                </section>
              )}
              {/* {null != popup && <CategoryCTAModal block={popup?.categoryPopup?.popup} post={post.title} category={popup?.name} />} */}
            </div>
            {false != bp.lg && <div className="d-none d-lg-block col-xl-4">
              <div className={`position-relative h-100`}>
                <PDFDownloadBlockMobile block={post.acf_post.pdfDownload} post={post.title} />
                <LastNews title={<span className="text-left d-block">Related Blogs</span>} useAlterPost={false} items={relatedPosts} />
              </div>
            </div>
            }
          </div>
        </div>
        {true != bp.lg && <section className={`d-lg-none ${s.sidebarMobile}`}>
          <div className="container mt-5">
            <LastNews title={<span className="text-left d-block">Related Blogs</span>} useAlterPost={false} items={relatedPosts} />
          </div>
        </section>}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xl-8">
                <div className={`${s.postFooter}`}>
                  <div className={s.shareBlock}>
                    <h4 className="d-block d-md-none">Share: </h4>
                    <div className={s.socials}>
                      <a
                        className=""
                        href={`https://www.facebook.com/sharer.php?u=${location.href
                          }&t=${parse(post.title)}`}
                        target="_blank"
                        rel="noreferrer"
                        data-original-title="Facebook"
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.0001 0.041626C4.47724 0.041626 0 4.51886 0 10.0417C0 14.995 3.60522 19.0972 8.33225 19.8916V12.128H5.91994V9.33417H8.33225V7.27413C8.33225 4.88389 9.79213 3.58135 11.9247 3.58135C12.9461 3.58135 13.8238 3.65746 14.0786 3.69098V6.18942L12.5995 6.19013C11.44 6.19013 11.2165 6.74102 11.2165 7.54969V9.33274H13.9832L13.6223 12.1265H11.2165V19.9584C16.1642 19.3562 20 15.1495 20 10.0388C20 4.51886 15.5228 0.041626 10.0001 0.041626Z" fill="#010002" />
                        </svg>
                        <span>Share on Facebook</span>
                      </a>
                      <a
                        className=""
                        href={`https://twitter.com/share?url=${location.href
                          }&text=${parse(post.title)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-original-title="Twitter"
                      >
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.6169 0.00896646C18.763 0.611275 17.8176 1.07195 16.8171 1.37324C16.28 0.755757 15.5663 0.318104 14.7725 0.119469C13.9786 -0.0791667 13.1429 -0.0292013 12.3784 0.262607C11.6138 0.554416 10.9573 1.07399 10.4977 1.75105C10.0381 2.42812 9.7975 3.23001 9.80847 4.04827V4.93995C8.24146 4.98058 6.68873 4.63305 5.28855 3.92829C3.88838 3.22354 2.68424 2.18345 1.78336 0.900645C1.78336 0.900645 -1.78336 8.92575 6.24175 12.4925C4.40536 13.739 2.21775 14.364 0 14.2758C8.02511 18.7342 17.8336 14.2758 17.8336 4.02152C17.8328 3.77315 17.8089 3.52539 17.7622 3.28143C18.6723 2.38395 19.3145 1.25082 19.6169 0.00896646Z" fill="black" />
                        </svg>
                        <span>Share on Twitter</span>
                      </a>
                    </div>
                  </div>
                  <div className={`${s.tagsWrapper}`}>
                    {post?.tags?.nodes?.length > 0
                      && <>
                        <span>Tags:</span> {post?.tags?.nodes.map((tag) => {
                          return <span key={tag.name}>{tag.name}</span>
                        })}
                      </>}
                  </div>
                  <AuthorBio author={author} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className={s.postLine}></section> */}

        {/* <section>
          <div className="container">
            <div className={s.singleNavigation}>
              {previous && (
                <Link to={previous.uri} rel="prev">
                  Previous
                </Link>
              )}
              {next && (
                <Link to={next.uri} rel="next">
                  Next
                </Link>
              )}
            </div>
          </div>
        </section> */}
        {/* <News title={<span className="text-center d-block">What to read next</span>} useAlterPost={true} /> */}
        <ContactForm block={{ showTitle: true }} />
      </article>
    </LayoutPost>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $authorId: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY HH:mm:ss")
      published: date(formatString: "MMMM DD, YYYY HH:mm:ss")
      uri
      acf_post {
        pdfDownload {
          show
          title
          subtitle
          file {
            localFile {
              publicURL
              base
            }
          }
          desktopImage{
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
          mobileImage{
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        relatedPosts {
          post {
            ... on WpPost {
              excerpt
              uri
              date(formatString: "MMMM DD, YYYY")
              title
              excerpt
              readingTime {
                text
              }
              featuredImage {
                node {
                  gatsbyImage(width: 600, quality: 80)
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 80)
                    }
                  }
                }
              }
              categories {
                nodes {
                  name
                  uri
                }
              }
            }
          }
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
          uri
          categoryPopup {
            popup {
              show
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              titleBefore
              title
              titleAfter
              button {
                url
                title
                target
              }
            }
          }
        }
      }
      readingTime {
        text
      }
      featuredImage {
        node {
          gatsbyImage(width: 600,quality: 80)
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 80
              )
              original {
                height
                width
              }
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            extension
            publicURL
          }
          width
          height
        }
        twitterImage {
          localFile {
            extension
            publicURL
          }
          width
          height
        }
        opengraphType
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    author: wpUser(id: {eq: $authorId}) {
      avatar {
        size
        url
      }
      name
      description
      email
      slug
      uri
      seo {
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
      posts {
        nodes {
          id
        }
      }
    }
    site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`;
